<!--
 * @Author       : zxlin
 * @Date         : 2023-10-10 10:45:05
 * @LastEditors: Hgh
 * @LastEditTime: 2024-11-19 14:48:18
 * @FilePath     : \wnl-sjxw-master\src\App.vue
 * @Description  :
-->
<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import { isIOS, isAndroid, isMiniProgram } from '@/utils/utils';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
function getUrlParams(url: any): any {
  try {
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1];
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&');
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=');
      obj[arr[0]] = arr[1];
    }
    return obj;
  } catch (e) {
    return {};
  }
}
onMounted(() => {
  if (
    isMiniProgram ||
    (window.location && window.location.href.includes('tclx'))
  ) {
    // console.log('小程序index')
  } else {
    setTimeout(() => {
      if (isIOS || isAndroid) {
        router.replace({
          path: '/m',
          query: { ...getUrlParams(location.href) },
        });
      } else {
        router.replace({
          path: '/',
          query: { ...getUrlParams(location.href) },
        });
      }
    });
  }
  // 埋点 官网主页展示
  let channelType = 'PC';
  if (isIOS) {
    channelType = 'IOS';
  }
  if (isAndroid) {
    channelType = 'Android';
  }
  window.UMHook('RedHomepage_IM', {
    channel: channelType,
  });
});
</script>

<style scoped lang="scss">
@use "@/utils/scss/var" as *;

@font-face {
  font-family: HYRY;
  src: url(#{$font}/source.ttf);
}

.pace .pace-progress {
  background: #6275ce;
}
</style>
