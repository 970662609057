import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import 'amfe-flexible';
import './assets/css/common.css';
import '@/utils/scss/reset.scss';
import { registerSW } from 'virtual:pwa-register';
import 'intersection-observer';
// 埋点
import '@/utils/um';
import axios from 'axios';
import { isIOS, isAndroid } from '@/utils/utils';
registerSW();

const pinia = createPinia();

createApp(App).use(router).use(pinia).mount('#app');

function getUrlParams(url: any): any {
  try {
    // 通过 ? 分割获取后面的参数字符串
    let temp_arr = url.split('?');
    let urlStr = temp_arr[temp_arr.length - 1];
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&');
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=');
      obj[arr[0]] = arr[1];
    }
    return obj;
  } catch (e) {
    return {};
  }
}
function getLid() {
  return getUrlParams(location.href).lid || '';
}
function getAppid() {
  return getUrlParams(location.href).appid || '';
}
function getClient() {
  if (isIOS) {
    return 'ios';
  } else if (isAndroid) {
    return 'android';
  } else {
    return '';
  }
}
function getChannel() {
  return 'red_book';
}
function getTrackId() {
  return (
    getUrlParams(location.href).trackId ||
    getUrlParams(location.href).trackid ||
    ''
  );
}
function getUa() {
  const ua = window.navigator.userAgent;
  if (isIOS) {
    return encodeURIComponent(ua.split('discover')[0].trim()) || '';
  } else if (isAndroid) {
    return encodeURIComponent(ua.split('Resolution')[0].trim()) || '';
  } else {
    return '';
  }
}
function getModel() {
  const ua = window.navigator.userAgent;
  const ans = ua.match(/device\/\((.*)\)/i);
  if (Array.isArray(ans)) {
    return ans[1].split(';')[1];
  } else {
    return '';
  }
}
function getAdCreativeId() {
  return getUrlParams(location.href).ad_creative_id || '';
}
function getAdOrderId() {
  return getUrlParams(location.href).ad_order_id || '';
}
function getAdGroupId() {
  return getUrlParams(location.href).ad_group_id || '';
}
function getAdPlanId() {
  return getUrlParams(location.href).ad_plan_id || '';
}
function redCallback() {
  let data = {
    lid: getLid(),
    // appid: getAppid(),
    appid: 'lovinlife',
    client: getClient(),
    channel: getChannel(),
    trackId: getTrackId(),
    ua: getUa(),
    model: getModel(),
    ad_creative_id: getAdCreativeId(),
    ad_order_id: getAdOrderId(),
    ad_group_id: getAdGroupId(),
    ad_plan_id: getAdPlanId(),
    // 以下满足后端传参,无实际意义
    oaid: '',
    Oudid: '',
    Idfa: '',
    Imei: '',
    Cid: '',
  };
  // let result = Object.keys(data).reduce((c, v) => {
  //   if (!c) {
  //     return false;
  //   } else if (!data[v]) {
  //     if (v === 'model') {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }, true);
  // if (!result) {
  //   return;
  // }
  axios({
    method: 'post',
    url: 'https://lyd.51wnl-cq.com/api/Yingji/RedReport',
    data,
  }).then((res) => {
    console.log(res, '归因');
  });
}
redCallback();
